<template>
  <div
    class="
      surface-ground
      px-4
      py-8
      md:px-6
      lg:px-8
      flex
      align-items-center
      justify-content-center
    "
  >
    <div class="surface-card p-4 shadow-2 border-round w-full lg:w-3">
      <div class="text-center mb-5">
        <img src="images/logo/logo.jpg" alt="Image" height="50" class="mb-3" />
        <div class="text-900 text-3xl font-medium mb-3">Welcome</div>
      </div>

      <div class="login-form">
        <label for="email1" class="block text-900 font-medium mb-2"
          >Email</label
        >
        <InputText
          v-model="input.email"
          id="email1"
          type="text"
          class="w-full mb-3"
          :class="{ 'p-invalid': v$.input.email.$error || errorFor('email') }"
        />
        <v-errors
          :serverErrors="errorFor('email')"
          :vuelidateErrors="{
            errors: v$.input.email.$errors,
            value: 'Email',
          }"
        ></v-errors>

        <label for="password1" class="block text-900 font-medium mb-2"
          >Password</label
        >
        <InputText
          v-model="input.password"
          id="password1"
          type="password"
          class="w-full mb-3"
          v-on:keyup.enter="submit"
          :class="{
            'p-invalid': v$.input.password.$error || errorFor('password'),
          }"
        />
        <v-errors
          :serverErrors="errorFor('password')"
          :vuelidateErrors="{
            errors: v$.input.password.$errors,
            value: 'Password',
          }"
        ></v-errors>

        <Button
          label="Sign In"
          icon="pi pi-user"
          class="w-full"
          @click="submit"
          :loading="isLoading"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  mixins: [validationErrors],
  data() {
    return {
      input: {
        email: "",
        password: "",
      },
      token: "",
      isLoading: false,
    };
  },
  validations() {
    return {
      input: {
        email: { required },
        password: { required },
      },
    };
  },
  methods: {
    ...mapActions(["auth/login"]),
    async submit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;

      this.isLoading = true;
      this["auth/login"](this.input)
        .then(() => {
          this.$router.push({ name: "dashboard" });
        })
        .catch((error) => {
          this.errors = error;
        });
      this.isLoading = false;
    },
  },
};
</script>